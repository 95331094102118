import React from 'react';
import { NavLink } from 'react-router-dom';
import url from 'utils/url';

function NodeLink({ node, onClickFn = () => {}, isCategory, categoryLabel, styles}) {

  let isExternal = false;
  let linkTarget = '';

  if (node.linkType?.field === "category" || (node.linkType?.field === undefined && node.key)) {
    linkTarget = node.key || node.linkType?.category;
    linkTarget = (linkTarget.match(/^\//) ? '' : '/') + linkTarget;
  } else {
    if (node.linkType?.link?.field === 'external' || node.name?.match(/^https?:\/\//)) {
      isExternal = true;
      linkTarget = node.linkType?.link?.external || node.name;
    } else {
      linkTarget = url.getPageLink(node.linkType?.link?.internal) || "";
      linkTarget = (linkTarget.match(/^\//) ? '' : '/') + linkTarget;
    }
  }

  const label = node.label || node.name || categoryLabel;

  return isExternal ? (
    <a key={node['@id']} href={linkTarget} target='_blank' rel='noopener noreferrer' onClick={onClickFn}>
      {label}
    </a>
  ) : (
    <NavLink
      className={styles}
      key={node['@id']}
      to={(isCategory ? '/categories' : '') + linkTarget}
      onClick={onClickFn}
      style={{ textDecoration: 'none' }}
    >
      {label}
    </NavLink>
  );
}

export default NodeLink;
