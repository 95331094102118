import React, { useState, useEffect } from 'react';
import { GetCategoryByKey, GetProductsByFilter } from 'utils/commerce';
import { get } from 'utils/magnolia';
import Settings from 'utils/Settings';
import Product from 'components/Product';
import Banner from 'components/Banner';
import FreshRelevance from 'components/FreshRelevance';

function Categories(props) {
  const [products, setProducts] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [sortProp, setSortProp] = useState(null);
  const [sortReverse, setSortReverse] = useState(null);
  const [campaignEnhancement, setCampaignEnhancement] = useState(null);
  const [bannerEnhancement, setBannerEnhancement] = useState(null);
  const [slotEnhancement, setSlotEnhancement] = useState(null);

  const { categoryKey } = props.match.params;

  useEffect(() => {
    const getProducts = async () => {
      const category = await GetCategoryByKey(categoryKey);
      const enhancementResults = await get('/.rest/ecommerce-categories?externalId="' + category.id + '"');
      const enhancement = enhancementResults?.results?.[0] || null;
      const categoryJson = {
        categoryId: category.id,
        definitionName: 'commercetools',
        connectionName: 'magnolia-services-media-demo',
      };
      let products = await GetProductsByFilter(JSON.stringify(categoryJson));

      if (enhancement?.visualMerchandise) {
        products = products.sort(
          (a, b) => enhancement.visualMerchandise.indexOf(a.id) - enhancement.visualMerchandise.indexOf(b.id)
        );
      }

      setProducts(products);
      setCampaignEnhancement(enhancement?.tag?.main?.[0] || null);
      setSlotEnhancement(enhancement?.includeFreshRelevance?.slot);

      if (enhancement?.bannerImage) {
        const banner = {
          isLarge: enhancement.isLarge,
          title: enhancement.bannerTitle,
          subtitle: enhancement.bannerSubtitle,
          textColor: enhancement.bannerTextColor,
          image: enhancement.bannerImage,
          cta: enhancement.BannerCta,
        };
        setBannerEnhancement(banner);
      }

      // send 'Category Viewed' Event to Segment
      if (window.analytics) {
        window.analytics.track('Category Viewed', {
          category_id: category.id,
          category_key: category.key,
          category_name: category?.name?.en,
        });
      }
    };

    getProducts();
  }, [categoryKey]);

  const sort = (e) => {
    const { type } = e.currentTarget.dataset;
    const newSort = {
      sortType: type,
      sortProp: undefined,
      sortReverse: undefined,
    };

    if (type === '0') {
      newSort.sortProp = 'name';
    } else if (type === '1') {
      newSort.sortProp = 'name';
      newSort.sortReverse = true;
    } else if (type === '2') {
      newSort.sortProp = 'price';
    } else if (type === '3') {
      newSort.sortProp = 'price';
      newSort.sortReverse = true;
    }

    setSortType(newSort.sortType);
    setSortProp(newSort.sortProp);
    setSortReverse(newSort.sortReverse);
  };

  const translations = Settings.getItem('translations');
  let sortedProducts = products && [...products];

  if (sortProp) {
    sortedProducts = sortedProducts.sort((a, b) =>
      a[sortProp].localeCompare(b[sortProp], undefined, { numeric: true })
    );
  }

  if (sortReverse) {
    sortedProducts = sortedProducts.reverse();
  }

  return products ? (
    <div className='Category'>
      {bannerEnhancement && <Banner {...bannerEnhancement} />}
      <div className='Sort'>
        <span className='title'>{translations.sortBy}</span>
        {[translations.nameAsc, translations.nameDesc, translations.priceAsc, translations.priceDesc].map(
          (label, i) => (
            <span
              key={'Sort__type-' + i}
              className='Sort__type'
              onClick={(e) => sort(e)}
              data-type={i}
              data-active={i === parseInt(sortType)}
            >
              {label}
            </span>
          )
        )}
        {sortProp && (
          <span className='Sort__type Sort__type--reset' onClick={(e) => sort(e)}>
            {translations.reset}
          </span>
        )}
      </div>
      <div className='wrapper'>
        {sortedProducts.map((product) => (
          <div key={product?.id} className='col col--1-4'>
            <Product product={product} />
          </div>
        ))}
      </div>
      {campaignEnhancement && <Banner {...campaignEnhancement} />}
      {slotEnhancement && <FreshRelevance slot={slotEnhancement} />}
    </div>
  ) : null;
}

export default Categories;
