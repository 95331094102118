import React from 'react';

import { getImageUrl } from 'utils/magnolia';

function Image(props) {
  const { image, imageChooser } = props;
  let actualImage;

  if (imageChooser) {
    switch(imageChooser.field) {
      case 'bynder':
        actualImage = imageChooser.bynderImage;
        break;
      case 'cloudinary':
        actualImage = imageChooser.cloudinaryImage;
        break;
      default:
        actualImage = imageChooser.image;
    }
  } else {
    actualImage = image;
  }

  return actualImage ? <img className='Image' src={getImageUrl(actualImage)} alt={actualImage['@name']} /> : null;
}

export default Image;
